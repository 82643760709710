@import "@swimlane/ngx-datatable/index.css";
@import "@swimlane/ngx-datatable/assets/icons.css";

:root {
  --primary: #09242c;
  --secondary: #00c795;
  /* #44D2B2; */
}

.prefix-css .datatable-header-cell-template-wrap{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

html,body{
  /* height: 100vh; */
  height: 100%;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
}
.ajuste-zoom {
  zoom: 1;
}

/* @media screen and (max-width: 1366px) {
  .ajuste-zoom{
    zoom: 0.67;
  }
}

@media screen and (min-width: 1367px) {
  .ajuste-zoom {
    zoom: 1;
  }
} */
.button-default{background: #FFFFFF;color: var(--primary);border: 1.5px solid var(--primary);}
.button-primary{background: var(--primary);color: #FFFFFF;border: 1.5px solid var(--primary);}
.button-secondary{background: #fff;color:#6c757d;border: 1.5px solid #6c757d;}
.button-danger{background: red;color: #FFFFFF;border: 1.5px solid red;}
.button-warning{background: yellow;color: #FFFFFF;border: 1.5px solid yellow;}
.button-success{background: green;color: #FFFFFF;border: 1.5px solid green;}

.button-default:hover{background: #f7f7f7;}
.button-primary:hover{background: #08252d;}
.button-secondary:hover{background: #e7e9eb75;}
.button-danger:hover{background: red;}
.button-warning:hover{background: yellow;}
.button-success:hover{background: green;}

.prefix-css .datatable-header, .prefix-css .datatable-header-inner{
  height: 46px !important;
}

.prefix-css .datatable-header-inner{
  /* color:var(--primary); */
  color: #09242c;
  font-weight: bold;
  padding: 14px 13px !important;
  font-size: 13px;
  background-color: #F2F3F5;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid #D8D8D8;
}

.prefix-css .datatable-body{
  overflow-x: hidden;
}

.prefix-css .datatable-body-cell {
  overflow: visible !important;
}

.prefix-css .datatable-body-cell-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.prefix-css .datatable-row-wrapper{
  z-index: 1005;
}

.prefix-css .datatable-body-row{
  height: 46px !important;
}

.prefix-css .datatable-body-row{
  /* color:var(--primary);*/
  color: #09242c;
  font-size: 13px;
}

.prefix-table-css .datatable-body-row{
  border-left: 5px solid var(--secondary);
  border-bottom: 1px solid #DFDFDF;
  border-right: 1px solid #DFDFDF;
}

.prefix-table-css .datatable-row-even{
  border-left: 5px solid var(--primary);
}

.prefix-card-css .ngx-datatable .datatable-body .datatable-body-row{
  margin: 15px;
  border-radius: 5px;
  box-shadow: 0px 4px 15px #24304421;
  width: calc(100% - 30px);
}

.ngx-datatable .datatable-footer{
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
  border: 1px solid #ebebeb;
  margin-top: -2px;
  border-radius:5px;
}

.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li{
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}

.ngx-datatable .datatable-footer .datatable-pager .pager,
.ngx-datatable .datatable-footer .datatable-pager .pager li {
  padding: 0;
  margin: 0;
  display: inline-block;
  list-style: none;
}

.ngx-datatable .datatable-footer .datatable-pager a{
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  vertical-align: top;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}

.ngx-datatable .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}

.ngx-datatable .datatable-footer .datatable-pager li.active a{
  background-color: rgba(158, 158, 158, 0.2);
  font-weight: bold;
}

.ngx-datatable .datatable-footer .datatable-pager .pager li a{
  cursor: pointer;
  display: inline-block;
}

.ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
  border-right: solid 2px #D8D8D8;
  margin: 0 4px;
}

.ngx-datatable .datatable-row-center,
.ngx-datatable .datatable-row-group,
.ngx-datatable .datatable-row-left,
.ngx-datatable .datatable-row-right{
  position: relative;
  margin-right: -5px;
}

.align-center{
  text-align: center;
}

.header-cell-center{
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.header-cell {
  display: flex !important;
  align-items: center !important;
  padding-right: 15px !important;
}

::-webkit-scrollbar {
  width: 7px; /* Largura do scrollbar vertical */
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background-color: #D9D9D9; /* Cor do puxador */
  border-radius: 6px; /* Borda do puxador */
  /* border-right: 2px white solid; */
  background-clip: padding-box;
  
}

/* CSS TABLE QUE É RENDERIZADA PELO SHOWDOWNJS NO CHATIA */
.message-text table {
  width: max-content;
}

.message-text table thead {}
.message-text table thead tr {}
.message-text table thead tr th {
  border-right: 1px solid #dcdcdc;
  padding: 8px 12px;
}
.message-text table thead tr th:nth-child(2n) {
  border-right: none;
}
.message-text table tbody {}
.message-text table tbody tr {}
.message-text table tbody td {
  border-top: 2px solid #a0a0a0;
  border-right: 2px solid #a0a0a0;
  padding: 8px 12px;
}
.message-text table tbody td:nth-child(2n) {
  border-right: none;
}
